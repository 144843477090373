import { css, Link, styled, useTheme } from "@mui/material"
import { RequestCallBackCard } from "../client-admin/RequestCallBackCard"
import { useTranslation } from "react-i18next"
import i18n from "../../i18n"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import Slider from "react-slick"
import {
  BenefitManagementContext,
  useBenefitManagementData,
} from "./BenefitManagementContext"
import React, { useContext } from "react"
import Loader from "../shared/Loader"
import NoticeBoardReordering from "./NoticeBoardReordering"
import BenefitContainer from "./Container"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import useIsMobileView from "../../utils/useIsMobileView"
import { ClientAdminLayoutContext } from "../client-admin/ClientAdminLayoutContext"
import NotificationStrip from "./NotificationStrip"

const BenefitManagement = () => {
  const { t, ready } = useTranslation("clientAdmin")
  const { data } = useBenefitManagementData()
  const sliderRef = React.useRef<Slider>(null)
  const theme = useTheme()
  const isMobile = useIsMobileView()
  const { canEditBenefits, commsHubLink, resourceLinkType } = useContext(
    ClientAdminLayoutContext
  )
  const commsHubBanner = useBuildAwsImageUrl(
    i18n.language,
    "commsHubBanner.png"
  )
  const commsHubBannerMobile = useBuildAwsImageUrl(
    i18n.language,
    "commsHubBannerMobile.png"
  )
  const resourceCentreBanner = useBuildAwsImageUrl(
    i18n.language,
    "resourceCentreBanner.png"
  )
  const resourceCentreBannerMobile = useBuildAwsImageUrl(
    i18n.language,
    "resourceCentreBannerMobile.png"
  )

  const mobileBanner =
    resourceLinkType === "comms_hub"
      ? commsHubBannerMobile
      : resourceCentreBannerMobile
  const desktopBanner =
    resourceLinkType === "comms_hub" ? commsHubBanner : resourceCentreBanner

  const settings = {
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: (
      <ChevronLeft
        sx={{
          color: theme.palette.primary.main,
          ":hover": { color: theme.palette.primary.main },
        }}
      />
    ),
    nextArrow: (
      <ChevronRight
        sx={{
          color: theme.palette.primary.main,
          ":hover": { color: theme.palette.primary.main },
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  if (!ready) {
    return null
  }

  return (
    <BenefitManagementContext.Provider value={data}>
      {data.loading ? (
        <Loader />
      ) : (
        <>
          {data.pendingOrdersCount > 0 && (
            <NotificationStrip orderCount={data.pendingOrdersCount} />
          )}
          <br />
          <br />
          <RequestsToOpenContainer>
            <h3>{t("clientAdmin:benefitManagement.heading")}</h3>

            {data.requestsToOpen && data.requestsToOpen.length > 0 && (
              <>
                <br />
                <br />
                <h4 style={{ marginBottom: "2rem" }}>
                  {t("clientAdmin:benefitManagement.requestsToOpen.heading")}
                </h4>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */}
                {/* @ts-ignore */}
                <Slider
                  ref={sliderRef}
                  {...settings}
                  className="request-to-open-slider"
                >
                  {data.requestsToOpen.map((cardData) => (
                    <CardContainer>
                      <RequestCallBackCard
                        totalNumber={cardData.number}
                        label={cardData.label}
                      />
                    </CardContainer>
                  ))}
                </Slider>
              </>
            )}
          </RequestsToOpenContainer>
          <BenefitContainer
            benefits={data.payrollPayBenefits}
            enabled={data.exploreYourBenefitsArea}
            canEdit={canEditBenefits}
          />
          {commsHubLink && (
            <div
              className="content-center"
              style={{ paddingBottom: "4rem", paddingTop: "4rem" }}
            >
              <Link
                href={commsHubLink}
                target="_blank"
                rel="noreferrer"
                style={{ maxWidth: "90%" }}
                component="a"
              >
                <img
                  src={isMobile ? mobileBanner : desktopBanner}
                  alt="Comms Hub Banner"
                  style={{
                    maxWidth: isMobile ? "100%" : "50rem",
                    borderRadius: theme.boxBorderRadius,
                  }}
                />
                <span className="screen-reader-only">(opens in a new tab)</span>
              </Link>
            </div>
          )}
          {data.noticeboards && (
            <>
              {(data.noticeboards.length > 0 || canEditBenefits) && (
                <NoticeBoardReordering
                  items={data.noticeboards}
                  canEdit={canEditBenefits}
                />
              )}
            </>
          )}
        </>
      )}
    </BenefitManagementContext.Provider>
  )
}

const CardContainer = styled("div")`
  & {
    max-width: 200px;
    padding: 0.6rem;
  }
`

export const RequestsToOpenContainer = styled("div")`
  ${({ theme }) => css`
    padding: 0 2rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 1108px;
      margin: auto;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
    }
  `}
`

export default BenefitManagement
